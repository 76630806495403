import { CardContent, Typography, Card, Button, Box } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import React from "react";
import { useNavigate } from "react-router";

function Ours(props) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  React.useEffect(() => {
    AOS.init();
  }, []);
  const navigate = useNavigate();
  return (
    <Box
      data-aos={props.anim_name}
      data-aos-easing={props.anim_easing}
      data-aos-duration={props.anim_duration}
    >
      <>
        <Card
          sx={{
            backgroundColor: "white",
            margin: { md: "60px 0px 60px 0px", sm: "0px" },
            width: "100%",
            height: "600px",
          }}
        >
          <CardContent sx={{ margin: "30px 5vw 80px 5vw" }}>
            <Typography
              sx={{
                color: "#D91010",
                textTransform: "uppercase",
                fontSize: { md: "25px", sm: "28px", xs: "22px" },
                textDecorationLine: "underline",
                textDecorationThickness: "3px",
                textUnderlineOffset: "10px",
                textAlign:"center"

              }}
              fontWeight={"800"}
            >
              {props.first_title}
            </Typography>
            <Typography
              sx={{
                color: "#D91010",
                fontSize: { md: "25px", sm: "28px", xs: "22px" },
                textTransform: "uppercase",
                textAlign:"center"

              }}
              fontWeight={"800"}
            >
              {props.second_title}
            </Typography>
            <Typography
              gutterBottom
              fontFamily={"Rubik,Sans-serif"}
              sx={{
                fontWeight: "500",
                mt: "8px",
                padding: "6px 0px",
                fontSize: { lg: "16.5px", md: "14px", sm: "15px", xs: "17px" },
                textAlign:"center",
                mb:"-10px"

              }}
            >
              {props.par}
            </Typography>
          </CardContent>
          <Box
            sx={{
              mt: { lg: "-60px", md: "-60px", sm: "-50px", xs: "-40px" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                navigate(props.routing);
                scrollToTop();
              }}
              sx={{
                mb: "60px",
                backgroundColor: props.btn_color,
                color: "white",
                width:"180px",
                padding: {
                  sm: "15px 30px",
                  xs: "20px 30px",
                },
                mt: "0px" ,
                borderRadius: "5 5 5 5",
                align: "center",
                fontWeight: "900",
                textTransform: "uppercase",
                "&:hover": { backgroundColor: props.btn_color_hover },
                cursor: "pointer",
                fontSize: { lg: "16px", md: "12px", sm: "12px", xs: "14px" },
              }}
            >
              {props.btn}
            </Button>
          </Box>
        </Card>
      </>
    </Box>
  );
}

export default Ours;
