import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import FamilyCard from "../../components/Family Card";
import AOS from "aos";
import "aos/dist/aos.css";
import { getFamilyMembers } from "../../utils/family-helper";
import { getImageUrl } from "../../utils/global_functions";
import FadeLoader from "react-spinners/FadeLoader";

export default function Family() {

  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getFamilyMembers();
      setSections(
        res.familyMembers.map((section) => {
          return {
            ...section,
            image: {
              src: getImageUrl(section.image.name),
              online: true,
              image: section.image,
              id: section.image._id,
            },
          };
        })
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    fetchData();
    AOS.init({
      disable: "mobile",
      once: "true",
      offset: 200,
      duration: 600,
      delay: 50,
    });
  }, []);

  return (
    <Box sx={{ backgroundColor: "#fff5e7" }}>

      {!loading ? (
        <FamilyCard data={sections} />
      ) : (
        <Box
          sx={{
            height: "100vh",
            backgroundColor: "#fff5e7",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FadeLoader color="#Febd00" />
          <p color="#1c2123">Page is loading...</p>
        </Box>
      )}
    </Box>
  );
}
