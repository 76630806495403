import { Box, Grid } from "@mui/material";
import React from "react";
import OptionBubble from "./option-bubble";

function HomePageOptions() {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "0px",
            md: "120px",
          },
        }}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        sx={{
          py: "20px",

          mb: "50px",
        }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <OptionBubble
            title={"Chicken Kabob"}
            img={require("../../assets/images/chickenwrap.png")}
            bg={"#FEBD01"}
            description={
              "JUICY CHICKEN GRILLED TO PERFECTION WITH MEDITERRANEAN SPICES "
            }
            textColor={"#D91010"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <OptionBubble
            title={"DOBRIK'S WINGS"}
            img={require("../../assets/images/dobrikswings.png")}
            textColor={"#FEBD01"}
            description={
              "DELICIOUSLY CRISPY WINGS, TOSSED IN YOUR SAUCE OF CHOICE."
            }
            bg={"#D91010"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <OptionBubble
            title={"MEAT KABOB"}
            img={require("../../assets/images/MeatKabob.png")}
            bg={"#FEBD01"}
            description={
              "ENJOY THE JUICY TENDERNESS OF OUR MEDITERRANEAN-INSPIRED MEAT KABOB!"
            }
            textColor={"#D91010"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomePageOptions;
