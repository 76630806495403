import { useEffect, useRef, useState } from "react";
import PopUP from ".";
import { useLocation } from "react-router-dom";

function PopupListner() {
  const [modal, setModal] = useState(false);
  const opened = useRef(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
    const loaded = sessionStorage.getItem("seenPopUp");
    if (loaded) {
      return;
    }
    sessionStorage.setItem("seenPopUp", true);
    if (opened.current) {
      return;
    }
    opened.current = true;
    if (window.location.pathname === "/") {
      setModal(true);
    }
  }, [location]);
  return (
    <PopUP
      open={modal}
      handleClose={() => {
        setModal(false);
      }}
    />
  );
}

export default PopupListner;
