import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function Jonah() {
  return (
    <Grid
      container
      alignItems={{
        xs: "center",
        md: "flex-end",
      }}
    >
      <Grid item xs={12} md={5}>
        <Box
          sx={{
            height: {
              xs: "200px",
              sm: "300px",
              md: "400px",
              lg: "500px",
            },
            minHeight: "25vh",
            width: "100%",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          <img
            src={require("../../assets/images/johna.png")}
            alt="catering"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "left bottom",
              marginBottom: "-10px",
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 4,
            height: {
              xs: "400px",
              sm: "450px",
            },
            minHeight: "25vh",
            width: "100%",
            display: {
              xs: "block",
              md: "none",
            },
          }}
        >
          <img
            src={require("../../assets/images/nickHome.png")}
            alt="catering"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: {
            xs: "20px",
            sm: "0px",
          },
          my: "50px",
        }}
      >
        <img
          src={require("../../assets/images/jon-txt.png")}
          alt="catering-txt"
          style={{
            width: {
              xs: "200px",
              sm: "250px",
              md: "300px",
              lg: "350px",
            },
            height: "auto",
            objectFit: "contain",
            marginBottom: "20px",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            fontWeight: "700",
            fontSize: {
              xs: "2rem",
              sm: "2.5rem",
            },
            maxWidth: "80%",
            color: "#FEBD01",
          }}
        >
          KABOB
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "550",
            fontSize: {
              xs: "1.2rem",
              sm: "1.4rem",
            },
            maxWidth: {
              xs: "90%",
              sm: "70%",
            },
            marginTop: {
              xs: "20px",
              sm: "30px",
            },
            marginBottom: "20px",
            color: "black",
            letterSpacing: "1px",
            lineHeight: "1.3",
          }}
        >
          Indulge in our Mediterranean haven, where laughter and irresistible
          flavors collide. Join our family as Nick's comedic genius and family
          Mediterranean culinary mastery combine for a tradition-infused
          experience that'll leave you craving more. Ignite your senses today!
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Jonah;
