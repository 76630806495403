import { Box } from "@mui/material";
import React from "react";

function HeroBackground() {
  return (
    <>
      <Box
        sx={{
          zIndex: -1,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <img
          src={require("../../assets/images/bg-home.png")}
          alt="bg-home"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "fill",
          }}
        />
      </Box>
      <Box
        sx={{
          zIndex: -1,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: {
            xs: "block",
            md: "none",
          },
        }}
      >
        <img
          src={require("../../assets/images/bg-home-mb.png")}
          alt="bg-home"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "fill",
          }}
        />
      </Box>
    </>
  );
}

export default HeroBackground;
