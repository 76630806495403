import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import fam from "../../assets/images/fammmm2.png";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../Dialog/index.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styles from "../Contactus/Phone-number.module.css";
import FormHelperText from "@mui/material/FormHelperText";
import { CreatePhoneNumber, CreateEmail } from "../../utils/all-helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#febd00",
    },
    secondary: {
      main: "#febd00",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    flexBasis: "25%",
    padding: "10px",
    height: "70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ExampleComponent = ({ targetDate }) => {
  const classes = useStyles();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [focused, setFocused] = useState(false);
  const [submit, setSubmit] = useState(false);

  const navigate = useNavigate();

  const ColoredLine = ({ color, width }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 2,
        width: width,
        borderColor: color,
      }}
    />
  );
  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      tab: 0,
    },
    validationSchema: Yup.object({
      email: Yup.string().when("tab", {
        is: 0,
        then: () =>
          Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required")
            .test(
              "valid-tld",
              "Email must have a valid top-level domain",
              (value) => {
                const tld = !!value && value.split(".").pop();
                return tld.length >= 2;
              }
            ),
      }),

      phone: Yup.string().when("tab", {
        is: 1,
        then: () =>
          Yup.string()
            .required("Phone number is required")
            .test(
              "isValidPhoneNumber",
              "Must be a valid phone number",
              (value) => !value || isValidPhoneNumber(value)
            ),
      }),
    }),
    onSubmit: async () => {
      if (formik.values.tab === 0) {
        try {
          const res = await CreateEmail({ email: formik.values.email });
          if (res.success) {
            formik.resetForm();
            setOpenDialog(true);
          } else {
            setErrorMsg(res.message);
          }
        } catch (error) {
          console.log(error);
          setErrorMsg(true);
        }
      } else if (formik.values.tab === 1) {
        try {
          const res = await CreatePhoneNumber({ phone: formik.values.phone });
          if (res.success) {
            formik.resetForm();
            setOpenDialog(true);
          } else {
            setErrorMsg(res.message);
          }
        } catch (error) {
          console.log(error);
        }
        setSubmit(false);
      }
    },
  });

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleErrorClose = () => {
    setErrorMsg(false);
  };

  useEffect(() => {
    const targetTime = new Date(targetDate).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetTime - now;
      if (distance < 0) {
        clearInterval(interval);
        navigate.push("/");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [targetDate, navigate]);

  return (
    <>
      <Box
        style={{
          height: "100%",
          backgroundColor: "#fff5e7",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={0}
          backgroundColor="#fff5e7"
          alignItems={"center"}
          sx={{
            padding: "0 0 5%",
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <Box
              position="relative"
              sx={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={fam}
                alt="Sticker"
                style={{
                  objectFit: "contain",
                  height: "auto",
                  width: "95%",
                  margin: "0 auto",
                }}
              />

              <Box sx={{ margin: { lg: "-1.5% 0% 10%" } }}>
                <ColoredLine color="#febd00" width={"80%"} />
                <img
                  src={require("../../assets/images/challenge.png")}
                  alt="Sticker"
                  style={{
                    objectFit: "contain",
                    height: "auto",
                    width: "70%",
                    margin: "0 auto",
                  }}
                />
                <ColoredLine color="#febd00" width={"80%"} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box position="relative" height="100%">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "bob",
                    fontWeight: "800",
                    fontSize: {
                      xs: "60px",
                      lg: "70px",
                    },
                    color: "#d91010",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {new Date(targetDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                  <br />
                  {new Date(targetDate).toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  gap: "10px",
                  padding: {
                    xs: 2,
                    sm: 3,
                    md: 4,
                    lg: 5,
                  },
                }}
              >
                <Paper
                  className={classes.paper}
                  sx={{ backgroundColor: "#febd00", borderRadius: "20px" }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Rubrik, sans-serif",
                      fontWeight: "600",
                      fontSize: {
                        xs: "27px",
                        lg: "35px",
                      },
                    }}
                  >
                    {days}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Rubrik, sans-serif",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Days
                  </Typography>
                </Paper>

                <Paper
                  className={classes.paper}
                  sx={{ backgroundColor: "#febd00", borderRadius: "20px" }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Rubrik, sans-serif",
                      fontWeight: "600",
                      fontSize: {
                        xs: "27px",
                        lg: "35px",
                      },
                    }}
                  >
                    {hours}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Rubrik, sans-serif",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Hours
                  </Typography>
                </Paper>

                <Paper
                  className={classes.paper}
                  sx={{ backgroundColor: "#febd00", borderRadius: "20px" }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Rubrik, sans-serif",
                      fontWeight: "600",
                      fontSize: {
                        xs: "27px",
                        lg: "35px",
                      },
                    }}
                  >
                    {minutes}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Rubrik, sans-serif",
                      fontWeight: "600",
                      fontSize: {
                        xs: "16px",
                        lg: "18px",
                      },
                    }}
                  >
                    Minutes
                  </Typography>
                </Paper>

                <Paper
                  className={classes.paper}
                  sx={{ backgroundColor: "#febd00", borderRadius: "20px" }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Rubrik, sans-serif",
                      fontWeight: "600",
                      fontSize: {
                        xs: "27px",
                        lg: "35px",
                      },
                    }}
                  >
                    {seconds}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Rubrik, sans-serif",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Seconds
                  </Typography>
                </Paper>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#d91010",
                    fontFamily: "Rubrik, sans-serif",
                    fontWeight: "800",
                    fontSize: { xs: "22px", sm: "22px", xl: "22px" },
                    padding: {
                      xs: "5px",
                      sm: "2px 30px 12px 30px",
                      xl: "0 0 10px",
                    },
                  }}
                >
                  Subscribe to stay updated!
                </Typography>

                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ paddingTop: { xs: "6%", sm: "4%" } }}
                  pt={"3%"}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <form onSubmit={formik.handleSubmit}>
                      <ThemeProvider theme={theme}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Tabs
                            centered
                            value={formik.values.tab}
                            onChange={(e, v) => {
                              formik.resetForm();
                              formik.setFieldValue("tab", v);
                            }}
                          >
                            <Tab label="Email" sx={{ color: "#d91010" }} />
                            <Tab label="Phone" sx={{ color: "#d91010" }} />
                          </Tabs>
                        </Box>
                        {formik.values.tab === 0 ? (
                          <TextField
                            type="email"
                            name="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={Boolean(
                              formik.touched.email && formik.errors.email
                            )}
                            value={formik.values.email}
                            disabled={formik.isSubmitting}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                            className={classes.textField}
                            sx={{
                              display: "flex",
                              width: "146%",
                              alignItems: "center",
                              marginRight: "20px",
                              left: "-48px",
                              "@media (max-width:275px)": {
                                width: "95%",
                                left: "2%",
                              },
                            }}
                            InputProps={{
                              style: {
                                backgroundColor: "white",
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: "#1c2123",
                                fontSize: "18px",
                                fontWeight: "600",
                              },
                            }}
                          />
                        ) : (
                          formik.values.tab === 1 && (
                            <Box>
                              <PhoneInput
                                placeholder="Phone Number"
                                value={formik.values.phone}
                                onChange={(value) => {
                                  formik.setFieldValue("phone", value);
                                  setSubmit(false);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("phone", true);
                                  setFocused(false);
                                }}
                                onFocus={() => {
                                  setFocused(true);
                                }}
                                className={styles.PhoneTimer}
                                style={{
                                  border:
                                    submit && formik.errors.phone
                                      ? "1px solid #d32f2f "
                                      : focused
                                      ? "1px solid #febd00"
                                      : "1px solid #BDBDBD",
                                  marginTop: "18px",
                                }}
                              />

                              {submit && formik.errors.phone ? (
                                <FormHelperText
                                  sx={{
                                    color: "#d32f2f",
                                    textAlign: "center",
                                  }}
                                >
                                  {formik.errors.phone}
                                </FormHelperText>
                              ) : null}
                            </Box>
                          )
                        )}
                      </ThemeProvider>
                      <Button
                        type="submit"
                        sx={{
                          width: "100%",
                          padding: {
                            sm: "15px 30px",
                            xs: "20px 30px",
                          },
                          marginTop: "15%",
                          backgroundColor: "#D91010",
                          color: "white",
                          fontWeight: "800",
                          fontFamily: "Rubik,sans-serif",
                          borderRadius: "10px",
                          fontSize: { xs: "15px", sm: "16px", xl: "18px" },
                          "&:hover": {
                            backgroundColor: "#D91010",
                          },
                        }}
                        onClick={() => {
                          if (formik.values.tab === 1) {
                            setSubmit(true);
                          }
                        }}
                      >
                        Subscribe
                      </Button>
                    </form>
                  </Box>

                  <CustomDialog
                    open={openDialog}
                    handleClose={handleClose}
                    title="Success"
                    message="You Successfully Subscribed!"
                    buttonText="Ok"
                  />
                  {errorMsg && (
                    <CustomDialog
                      open={!!errorMsg}
                      handleClose={handleErrorClose}
                      title="Failed"
                      message={errorMsg}
                      buttonText="Ok"
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ExampleComponent;
