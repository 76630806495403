import { Box, Typography } from "@mui/material";
import React from "react";

function OptionBubble({ title, img, description, bg, textColor }) {
  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: {
          xs: "330px",
          md: "380px",
          xl: "430px",
        },
        mx: "auto",
        height: {
          xs: "330px",
          md: "380px",
          xl: "430px",
        },
        flexFlow: "column",
        flexGrow: 1,
        justifyContent: "center",
        borderRadius: "100%",
        backgroundColor: bg,
        position: "relative",
        mt: {
          xs: "100px",
          md: "0px",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          mx: "auto",
          top: {
            xs: "-110px",
            sm: "-120px",
            md: "-120px",
            lg: "-120px",
          },
          width: {
            xs: "350px",
            lg: "400px",
          },
          height: {
            xs: "200px",
            lg: "250px",
          },
        }}
      >
        <img
          draggable="false"
          src={img}
          alt="order-now"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{
          color: textColor,
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "50px",
          fontFamily: "Permanent Marker",
          fontSize: "30px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: "white",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "20px",
          textTransform: "uppercase",
          maxWidth: "60%",
          mx: "auto",
          fontSize: "18px",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}

export default OptionBubble;
