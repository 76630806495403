import axios from "axios";
import { toast } from "react-toastify";

export const backendIP = process.env.REACT_APP_BACKEND_URL || "http://localhost:8080";

export function getImageUrl(image) {
  return `${backendIP}/images/${image}`;
}

export async function ApiCall({
  api,
  map = {},
  post = false,
  put = false,
  del = false,
  params = {},
  isFile = false,
  exception = false,
  dontLog = false,
}) {
  const headers = {
    "Content-Type": isFile ? "multipart/form-data" : "application/json",
    "x-access-token": localStorage.getItem("token"),
  };
  const url = `${backendIP}/api/${api}`;
  try {
    const response = post
      ? await axios.post(url, map, {
          headers: headers,
          params: params,
        })
      : put
      ? await axios.put(url, map, {
          headers: headers,
          params: params,
        })
      : del
      ? await axios.delete(url, {
          headers: headers,
          params: params,
          data: map,
        })
      : await axios.get(
          url,
          {
            headers: headers,
            params: params,
          },
          map
        );
    if (response.status === 401 && !exception) {
      localStorage.removeItem("token");
      CreateToast({
        message: "Session expired, please login again",
        error: true,
      });
      window.location.href = "/login";
      return { success: false };
    }
    return response.data;
  } catch (error) {
    if (!dontLog) console.log(error);
    if (error.response && error.response.status === 401 && !exception) {
      localStorage.removeItem("token");
      CreateToast({
        message: "Session expired, please login again",
        error: true,
      });
      window.location.href = "/login";
    }
    return error.response
      ? error.response.data
        ? error.response.data
        : { success: false }
      : { success: false };
  }
}

export function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function CreateToast({
  message,
  hasIcon = true,
  id,
  infinite,
  error = false,
  warning = false,
}) {
  const options = {
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    toastId: id,
    autoClose: infinite ? false : 3000,
    icon: hasIcon ? undefined : false,
  };
  error
    ? toast.error(message, options)
    : warning
    ? toast.warning(message, options)
    : toast.success(message, options);
}

export function CancelToast({ id }) {
  if (!id) return;
  try {
    toast.dismiss(id);
  } catch (error) {
    console.log(error);
  }
}

export function getArrayString(array) {
  let string = "[";
  for (let i = 0; i < array.length; i++) {
    string += `"${array[i]}"`;
    if (i !== array.length - 1) {
      string += ", ";
    }
  }
  string += "]";
  return string;
}

export async function SendEmail({
  allUsers,
  exceptUsers,
  selectedUsers,
  subject,
  message,
}) {
  const res = await ApiCall({
    api: "email",
    post: true,
    map: {
      ids: allUsers ? exceptUsers : selectedUsers,
      subject,
      message,
      allUsers,
    },
  });
  return {
    state: res.success,
    message: res.message,
  };
}
