import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import Man from "../../assets/images/Option-02.png";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomDialog from "../Dialog/index.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styles from "./Phone-number.module.css";
import { FormHelperText } from "@mui/material";
import { createContact } from "../../utils/all-helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#febd00",
    },
    secondary: {
      main: "#febd00",
    },
  },
});
const useStyles = makeStyles({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2%",
    paddingTop: 40,
    paddingBottom: 40,
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
    width: "80%",
  },
  heading: {
    fontWeight: "800",
    fontFamily: "Rubrik, Sans-serif",
    marginBottom: 20,
    color: "#d91010",
    fontSize: "50px",
  },
  textField: {
    marginBottom: 20,
    backgroundColor: "#ff5e7",
    width: "100%",
    flexDirection: "column",
  },
  button: {
    backgroundColor: "#d91010",
    color: "white",
    fontWeight: "800",
    fontSize: "18px",
    marginTop: 20,
    [theme.breakpoints.up("sm")]: {
      padding: "15px 30px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "15px 30px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "20px 30px",
    },
    "&:hover": {
      backgroundColor: "#d91010",
    },
  },
  image: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "50%",
      width: "90%",
      marginTop: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
    },
  },
});

function ContactUs() {
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [focused, setFocused] = useState(false);
  const classes = useStyles();

  const handleErrorClose = () => {
    setErrorMsg(false);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .test(
          "not-only-spaces",
          "Name must not be only spaces",
          (value) => !!value && value.trim().length > 0
        ),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required")
        .test(
          "valid-tld",
          "Email must have a valid top-level domain",
          (value) => {
            const tld = !!value && value.split(".").pop();
            return tld.length >= 2;
          }
        ),
      phone: Yup.string().test(
        "isValidPhoneNumber",
        "Must be a valid phone number",
        (value) => !value || isValidPhoneNumber(value)
      ),
      message: Yup.string()
        .required("Message is required")
        .min(10, "Message must be at least 10 characters"),
    }),
    onSubmit: async () => {
      try {
        const res = await createContact(formik.values);
        if (res.success) {
          formik.resetForm();
          setOpenDialog(true);
        } else {
          setErrorMsg(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          width: "100%",
          pt: {
            xs: "90px",
            md: "100px",
            lg: "110px",
          },
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -2,
            backgroundColor: "#FFF8EE",
          }}
        />
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
            overflow: "hidden",
          }}
        >
          <img
            src={require("../../assets/images/des-c.png")}
            alt="design"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              maxWidth: "70%",
              objectFit: "contain",
              zIndex: -1,
              userSelect: "none",
            }}
            draggable={false}
          />
          <img
            src={require("../../assets/images/des-c-2.png")}
            alt="design"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              maxWidth: "70%",
              objectFit: "contain",
              zIndex: -1,
              userSelect: "none",
            }}
            draggable={false}
          />
        </Box>
        <ThemeProvider theme={theme}>
          <Grid
            container
            spacing={0}
            alignItems={"start"}
            justifyContent={"center"}
            sx={{
              zIndex: 999999,
            }}
          >
            <Grid
              item
              xs={12}
              md={5}
              xl={5}
              sx={{
                pt: {
                  xs: "50px",
                  sm: "50px",
                  md: "200px",
                  lg: "200px",
                },
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "85%",
                  },
                  margin: "auto",
                }}
              >
                <img
                  draggable={false}
                  src={Man}
                  alt="Nick Antonyan"
                  className={classes.image}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    margin: "auto",
                    userSelect: "none",
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={7}
              xl={7}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ mb: "10px" }}>
                <Container maxWidth="md" className={classes.container}>
                  <Typography
                    variant="h4"
                    className={classes.heading}
                    style={{ textTransform: "uppercase" }}
                    fontFamily={"bob"}
                    sx={{
                      fontSize: {
                        xs: "60px",
                        lg: "70px",
                      },
                    }}
                  >
                    Say Hi To Nick
                  </Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      fullWidth
                      helperText={formik.touched.name && formik.errors.name}
                      margin="normal"
                      name="name"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.name}
                      disabled={formik.isSubmitting}
                      label="Name"
                      required
                      className={classes.textField}
                      sx={{
                        display: "flex",
                        textAlign: "left",
                      }}
                      InputProps={{
                        style: {
                          backgroundColor: "white",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#1c2123",
                          fontSize: "18px",
                          fontWeight: "600",
                        },
                      }}
                    />
                    <TextField
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      required
                      margin="normal"
                      error={Boolean(
                        formik.touched.email && formik.errors.email
                      )}
                      value={formik.values.email}
                      disabled={formik.isSubmitting}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      helperText={formik.touched.email && formik.errors.email}
                      className={classes.textField}
                      sx={{
                        display: "flex",
                        textAlign: "left",
                      }}
                      InputProps={{
                        style: {
                          backgroundColor: "white",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#1c2123",
                          fontSize: "18px",
                          fontWeight: "600",
                        },
                      }}
                    />

                    <PhoneInput
                      placeholder="Phone Number"
                      value={formik.values.phone}
                      onChange={(value) => formik.setFieldValue("phone", value)}
                      onBlur={() => {
                        formik.setFieldTouched("phone", true);
                        setFocused(false);
                      }}
                      onFocus={() => {
                        setFocused(true);
                      }}
                      className={styles.mainPhoneNumber}
                      style={{
                        border:
                          formik.touched.phone && formik.errors.phone
                            ? "2px solid #d32f2f "
                            : focused
                            ? "2px solid #febd00"
                            : "1px solid #BDBDBD",
                      }}
                    />

                    {formik.touched.phone && formik.errors.phone ? (
                      <FormHelperText
                        sx={{ color: "#d32f2f", textAlign: "left" }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;{formik.errors.phone}
                      </FormHelperText>
                    ) : null}

                    <TextField
                      type="text"
                      name="message"
                      label="Message"
                      variant="outlined"
                      fullWidth
                      required
                      multiline
                      rows={4}
                      className={classes.textField}
                      error={Boolean(
                        formik.touched.message && formik.errors.message
                      )}
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                      margin="normal"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      disabled={formik.isSubmitting}
                      sx={{
                        display: "flex",
                        textAlign: "left",
                      }}
                      InputProps={{
                        style: {
                          backgroundColor: "white",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#1c2123",
                          fontSize: "18px",
                          fontWeight: "600",
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#D91010 !important",
                        color: "white !important",
                        marginTop: "20px",
                        fontSize: {
                          xs: "26px",
                          md: "28px",
                        },
                        borderRadius: "10px",
                        minWidth: "180px",
                        padding: "4px 20px",
                        marginBottom: "50px",
                      }}
                      type="submit"
                    >
                      Send Message
                    </Button>

                    <CustomDialog
                      open={openDialog}
                      handleClose={handleClose}
                      title="Success"
                      message="Message sent successfully!"
                      buttonText="Ok"
                    />
                  </form>
                </Container>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "#febd00",
                    mb: "10px",
                    fontSize: {
                      xs: "40px",
                      sm: "50px",
                      md: "50px",
                      lg: "60px",
                    },
                    textAlign: { xs: "center", md: "left", xl: "left" },
                    mt: { xl: "5%" },
                  }}
                  fontFamily="Rubik, sans-serif"
                  fontSize={"50px"}
                  fontWeight={"600"}
                >
                  <img
                    alt=""
                    src={require("../../assets/images/come.png")}
                    style={{
                      width: "95%",
                      maxWidth: "95%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  spacing={0}
                  sx={{ maxWidth: { lg: "100%", md: "100%" } }}
                >
                  <Grid item xs={12} sm={12} md={6} xl={6}>
                    <Box>
                      <Box
                        component={"a"}
                        href="https://www.google.com/maps/place/12461+Oxnard+St,+Valley+Glen,+CA+91606,+USA/@34.1796272,-118.4050574,3a,75y,50.66h,82.13t/data=!3m6!1e1!3m4!1sQNsxQoEV71CzfNKMmtwQEQ!2e0!7i16384!8i8192!4m7!3m6!1s0x80c2966fe0a9add3:0xda121edb02bc9bd9!8m2!3d34.1797432!4d-118.4050531!10e5!16s%2Fg%2F11bw4ncjz0?entry=ttu"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#1c2123",
                            mt: "5px",
                            fontSize: {
                              xs: "25px",
                              sm: "30px",
                            },
                          }}
                          textTransform="uppercase"
                          fontFamily="Rubik, sans-serif"
                          letterSpacing="-1px"
                          fontWeight="bold"
                        >
                          12461 Oxnard St,
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1c2123",
                            mt: "5px",
                            fontSize: {
                              xs: "25px",
                              sm: "28px",
                            },
                          }}
                          textTransform="uppercase"
                          fontFamily="Rubik, sans-serif"
                          letterSpacing="-1px"
                          fontWeight="bold"
                        >
                          Valley Glen, CA 91606
                        </Typography>
                      </Box>
                      <Typography
                        component={"a"}
                        href="tel:+1 818-856-8880"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "#D91010",
                          mt: "5px",
                          fontSize: {
                            xs: "25px",
                            sm: "35px",
                          },
                          textDecoration: "none",
                        }}
                        textTransform="uppercase"
                        fontFamily="Rubik, sans-serif"
                        letterSpacing="-1px"
                        fontWeight="bold"
                      >
                        +1 818-856-8880
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      my: "25px",
                      display: {
                        xs: "block",
                        md: "none",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "80%",
                        maxWidth: "500px",
                        mx: "auto",
                        height: "10px",
                        borderRadius: "10px",
                        background: "#fedb00",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                    sx={{
                      mb: { md: "230px", lg: "140px", sm: "80px" },
                    }}
                  >
                    <Box
                      sx={{
                        borderLeft: {
                          xs: "none",
                          md: "4px solid #fedb00",
                        },
                        paddingLeft: {
                          xs: 0,
                          md: "20px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#D91010",
                          fontSize: {
                            xs: "25px",
                            sm: "30px",
                          },
                        }}
                        textTransform="uppercase"
                        fontFamily="Rubik, sans-serif"
                        letterSpacing="-1px"
                        fontWeight="bold"
                      >
                        Monday - Thursday
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1c2123",
                          fontSize: {
                            xs: "22px",
                          },
                        }}
                        textTransform="uppercase"
                        fontFamily="Rubik, sans-serif"
                        letterSpacing="-1px"
                        fontWeight="bold"
                      >
                        11:00 am - 9:00 pm
                      </Typography>
                      <Typography
                        sx={{
                          color: "#D91010",
                          fontSize: {
                            xs: "25px",
                            sm: "30px",
                          },
                        }}
                        textTransform="uppercase"
                        fontFamily="Rubik, sans-serif"
                        letterSpacing="-1px"
                        fontWeight="bold"
                      >
                        Friday - Sunday
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1c2123",
                          mb: "20px",
                          fontSize: {
                            xs: "22px",
                          },
                        }}
                        textTransform="uppercase"
                        fontFamily="Rubik, sans-serif"
                        letterSpacing="-1px"
                        fontWeight="bold"
                      >
                        11:00 am - 10:00 pm
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {errorMsg && (
              <CustomDialog
                open={!!errorMsg}
                handleClose={handleErrorClose}
                title="Failed"
                message={errorMsg}
                buttonText="Ok"
              />
            )}
          </Grid>
        </ThemeProvider>
      </Box>
    </>
  );
}
export default ContactUs;
