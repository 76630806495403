import { Box } from "@mui/material";

export default function PrivacyPage() {
  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#fff5e7",
      }}
    >
      <>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",
            textAlign: "center",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "15pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Privacy Policy&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",
            textAlign: "center",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Last updated: June 15, 2023
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <br />
          </span>
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            About This Policy:&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            This policy describes our privacy practices for the websites where
            it is posted. It also applies to our practices for our mobile phone
            text programs.
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Definitions
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            For the purposes of this Privacy Policy:
          </span>
        </p>
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Account&nbsp;
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                means a unique account created for You to access our Service or
                parts of our Service.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Affiliate
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;means an entity that controls, is controlled by or is
                under common control with a party, where "control" means
                ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other
                managing authority.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Application
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;refers to Jonah's Kabob, the software program provided by
                the Company.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Company
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;(referred to as either "the Company", "We", "Us" or "Our"
                in this Agreement) refers to Jonah's Kabob, 12461 Oxnard St, Los
                Angeles, CA 91606.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Country
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;refers to: California, United States
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Device
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;means any device that can access the Service such as a
                computer, a cell phone or a digital tablet.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Personal
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Data
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;is any information that relates to an identified or
                identifiable individual.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Service
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;refers to the Application and Website.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Service Provider
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;means any natural or legal person who processes the data
                on behalf of the Company. It refers to third-party companies or
                individuals employed by the Company to facilitate the Service,
                to provide the Service on behalf of the Company, to perform
                services related to the Service or to assist the Company in
                analyzing how the Service is used.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Usage Data
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;refers to data collected automatically, either generated
                by the use of the Service or from the Service infrastructure
                itself (for example, the duration of a page visit).
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                "You"
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;means the individual accessing or using the Service, or
                the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </span>
            </p>
          </li>
        </ul>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Information We Collect&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            While using our service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information will
            include:&nbsp;
          </span>
        </p>

        <br />
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Email Address&nbsp;
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                First and last name
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Phone Number&nbsp;
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Usage Data&nbsp;
              </span>
            </p>
          </li>
        </ul>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Usage Data
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;is collected automatically when using the Service.&nbsp;
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Usage Data may include information such as browser type, browser
            version, the pages of our Service that are visited, the time and
            date of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.&nbsp;
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, the IP address of
            Your mobile device, Your mobile operating system, the type of mobile
            Internet browser You use, unique device identifiers and other
            diagnostic data.
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            How We Collect Information
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We collect information directly from you. This happens when you
            register on a website or applicant, whether for an offer, event or
            for one of our programs or others. We also collect information if
            you leave comments or make contact requests.
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            How We Use Information
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We use your information as is disclosed at the time we collect the
            information and in the following ways:
          </span>
        </p>

        <br />
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To provide and maintain our Service
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                , including to monitor the usage of our Service.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To manage Your Account
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                : to manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                For the performance of a contract:
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;the development, compliance and undertaking of the
                purchase contract for the products, items or services You have
                purchased or of any other contract with Us through the Service.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To contact You:
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To provide You with news, special offers and general information
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;about other goods, services and events which we offer that
                are similar to those that you have already purchased or enquired
                about unless You have opted not to receive such information.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To improve our products and services and for related business
                purposes
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                . For example, we use information to understand our visitors and
                customers. This includes looking at website usage trends. We
                also use your information to customize your experience with us.
                We may do these activities using the tracking tools described in
                this policy.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                For security purposes.
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;For example, we might use information to protect our
                website or our company. We might also use information to protect
                our customers or our business partners.
              </span>
            </p>
          </li>
        </ul>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Retention of Your Personal Data
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Information Sharing
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We may share Your personal information in the following situations:
          </span>
        </p>

        <br />
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                With Service Providers
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                : We may share Your personal information with Service Providers
                to monitor and analyze the use of our Service, to contact You.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                For business transfers:
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;We may share or transfer Your personal information in
                connection with, or during negotiations of, any merger, sale of
                Company assets, financing, or acquisition of all or a portion of
                Our business to another company.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                With Affiliates:
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;We may share Your information with Our affiliates, in
                which case we will require those affiliates to honor this
                Privacy Policy. Affiliates include Our parent company and any
                other subsidiaries, joint venture partners or other companies
                that We control or that are under common control with Us.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                With business partners:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We may share Your information with Our business partners to
                offer You certain products, services or promotions.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                With other users:
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;when You share personal information or otherwise interact
                in the public areas with other users, such information may be
                viewed by all users and may be publicly distributed outside.
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                With Your consent:
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;We may disclose Your personal information for any other
                purpose with Your consent.
              </span>
            </p>
          </li>
        </ul>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Security
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We use industry standard measures to protect personal information.
            We cannot guarantee that you will be completely secure, however. We
            recommend that you use caution when using the internet or mobile
            devices.
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Refund/ Return Policy&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Refunds will be done only through the Original Mode of
            Payment.&nbsp;
          </span>
        </p>

        <br />

        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Changes to this Privacy Policy &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </span>
        </p>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </span>
        </p>

        <br />

        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "13pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Contact Us &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If you have any questions about this Privacy Policy, You can contact
            us:
          </span>
        </p>
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                By email
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                :&nbsp;
              </span>
              <a
                href="mailto:admin@digitaldojo.ai"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Arial",
                    color: "#1155cc",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  admin@digitaldojo.ai
                </span>
              </a>
            </p>
          </li>
        </ul>

        <br />
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Last updated: June 15, 2023&nbsp;
          </span>
        </p>
      </>
    </Box>
  );
}
