import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";
import "swiper/css";

function FamilyCards({ data }) {
  const [swiper, setSwiper] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "calc(100vh - 200px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: {
          xs: "100px",
          md: "110px",
          lg: "120px",
        },
      }}
    >
      {data.length === 0 ? (
        <Typography
          variant="h4"
          sx={{ color: "text.disabled", maxWidth: "70%" }}
          textAlign={"center"}
        >
          No Family Members Yet, Stay Tuned!
        </Typography>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: {
                xs: 1,
                md: 2,
              },
              mb: {
                xs: "20px",
                md: "50px",
              },
            }}
          >
            <Box
              sx={{
                flex: 1,
                height: "5px",
                backgroundColor: "#F2B643",
              }}
            />
            <Typography
              sx={{
                textAlign: "center",
                color: "#d91010",
                fontWeight: "800",
                fontSize: { lg: "50px", xs: "45px" },
                fontFamily: "bob, sans-serif",
                maxWidth: "70%",

                mx: 2,
              }}
            >
              {data[activeIndex]?.name}
            </Typography>

            <Box
              sx={{
                flex: 1,
                height: "5px",
                backgroundColor: "#F2B643",
              }}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "5%",
                top: {
                  xs: "105%",
                  md: "50%",
                },
                transform: {
                  xs: null,
                  md: "translateY(-50%)",
                },
                zIndex: 1000,
              }}
            >
              <img
                src={require("../../assets/images/leftArrow.png")}
                alt="left-arrow"
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() => {
                  swiper.slidePrev();
                }}
              />
            </Box>
            <Swiper
              loop
              style={{ width: "100%" }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                960: {
                  slidesPerView: 2,
                  spaceBetween: -50,
                },
                1280: {
                  ...(data.length <= 2
                    ? {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      }
                    : { slidesPerView: 3, spaceBetween: -100 }),
                },
                1920: {
                  ...(data.length <= 2
                    ? {
                        slidesPerView: 5,
                        spaceBetween: 40,
                      }
                    : { slidesPerView: 4, spaceBetween: -100 }),
                },
              }}
              onSwiper={setSwiper}
              centeredSlides
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.realIndex);
              }}
            >
              {data.map((item, index) => (
                <SwiperSlide
                  key={item._id}
                  style={{
                    userSelect: "none",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "57% 43% 76% 24% / 46% 44% 56% 54%",
                      backgroundColor: "black",
                      width: {
                        xs: "90%",
                        sm: "95%",
                        md: "100%",
                      },
                      mx: "auto",
                      aspectRatio: "1/1",
                      overflow: "hidden",
                      transform:
                        activeIndex !== index && data.length > 2
                          ? "scale(0.5)"
                          : "scale(1)",
                      transition: "all 0.3s ease-in-out",
                      filter:
                        activeIndex !== index && data.length > 2
                          ? "blur(5px)"
                          : "blur(0px)",
                      border:
                        index % 2 === 0
                          ? "6px solid #ED1C24"
                          : "6px solid #FEBD01",
                    }}
                  >
                    <img
                      draggable="false"
                      src={item.image.src}
                      alt="order-now"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
            <Box
              sx={{
                position: "absolute",
                right: "5%",
                top: {
                  xs: "105%",
                  md: "50%",
                },
                transform: {
                  xs: null,
                  md: "translateY(-50%)",
                },
                zIndex: 1000,
              }}
            >
              <img
                src={require("../../assets/images/rightArrow.png")}
                alt="right-arrow"
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() => {
                  swiper.slideNext();
                }}
              />
            </Box>
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              color: "#FEBD00",
              fontWeight: "900",
              fontSize: { lg: "50px", xs: "45px" },
              fontFamily: "bob, sans-serif",
              maxWidth: "70%",
              mx: "auto",
              mt: {
                xs: "30px",
                md: "50px",
              },
            }}
          >
            {data[activeIndex]?.role}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "#ED1C24",
              fontWeight: "600",
              fontSize: { lg: "40px", xs: "30px" },
              maxWidth: "70%",
              mx: "auto",
              mb: 2,
            }}
          >
            {data[activeIndex]?.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default FamilyCards;
