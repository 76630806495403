import { Box, Button, Grid, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import design3 from "../../assets/images/design3.png";
import design16 from "../../assets/images/design16.png";

function CardPic(props) {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      offset: 200,
      duration: 1000,
      delay: 50,
    });
  }, []);

  const {
    heading,
    subheading,
    slogan,
    description,
    buttonText,
    imageSrc,
    headingColor,
    subheadingColor,
    descriptionColor,
    buttonColor,
    buttonBgColor,
    bgColor,
    showButton,
  } = props;

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
        width: "120px",
        borderColor: "#1c2123",
      }}
    />
  );
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        alignItems={"center"}
        sx={{ backgroundColor: "#fff5e7", padding: "20px" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          sx={{  zIndex: "3" }}
        >
          <img
            src={imageSrc}
            alt="food"
            style={{width: "100%", objectFit: "contain" }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6} style={{ backgroundColor: bgColor }}>
          <CardContent
            data-aos="fade-down"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mt: "20px" }}>
              <ColoredLine color="#1c2123" />
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  color: headingColor,
                  textTransform: "uppercase",
                  fontSize: { xs: "25px", sm: "27px" },
                  fontFamily: "Rubik, Sans-Serif",
                  pl: "12px",
                  pr: "12px",
                }}
                fontWeight={"795"}
              >
                {heading}
              </Typography>
              <ColoredLine color="#1c2123" />
            </Box>

            <Typography
              variany="body1"
              sx={{
                color: subheadingColor,
                textTransform: "uppercase",
                fontSize: { xs: "40px", sm: "50px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                lineHeight: "1.2",
                fontFamily: "Rubik, Sans-Serif",
                marginTop: "2%",
                whiteSpace: "pre-wrap",
              }}
              fontWeight={"800"}
            >
              {subheading}
            </Typography>

            <Typography
              sx={{
                color: "#1c2123",
                textTransform: "uppercase",
                fontSize: { xs: "16px", sm: "22px" },
                m: "10px",
                marginTop: "18px",
                padding: "10px 0px",
                fontFamily: "Rubik, Sans-Serif",
              }}
              fontWeight={"700"}
            >
              {slogan}
            </Typography>

            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              sx={{
                color: descriptionColor,
                fontSize: { xs: "16px", sm: "19px" },
                m: "10px",
                mt: "7px",
                pl: "80px",
                pr: "80px",
                padding: "8px 0px",
                fontFamily: "Rubik, Sans-Serif",
                fontWeight: "400",
                lineHeight: "1.7",
                width: "75%",
                zIndex: "2",
              }}
            >
              {description}
            </Typography>
            {showButton && (
              <Button
                onClick={() => {
                  navigate("/contact-us");
                  scrollToTop();
                }}
                variant="contained"
                sx={{
                  mt: "8px",
                  borderRadius: "5",
                  padding: {
                    lg: "15px 30px",
                    xs: "20px 30px",
                  },
                  fontSize: "18px",
                  fontFamily: "Rubik, Sans-Serif",
                  fontWeight: "800",
                  "&:hover": { backgroundColor: "#d91010" },
                  cursor: "pointer",
                  backgroundColor: buttonBgColor,
                  color: buttonColor,
                  zIndex: "3",
                }}
              >
                {buttonText}
              </Button>
            )}
          </CardContent>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          position: "relative",
          display: {
            xl: "block",
            lg: "none",
            md: "none",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <img
          src={design16}
          alt="design"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: "80vh",
            width: "40vh",
            zIndex: "1",
          }}
        />
      </Grid>
      {!isMobile && (
        <Grid
          item
          xs={12}
          md={6}
          sx={{ position: "relative", display: { md: "none", xl: "block" } }}
        >
          <img
            src={design3}
            alt="design"
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: "29vh",
              width: "39vh",
              zIndex: "1",
            }}
          />
        </Grid>
      )}
    </>
  );
}

export default CardPic;
