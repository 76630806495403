import {
  AppBar,
  Button,
  Box,
  Toolbar,
  useMediaQuery,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import DrawerComp from "../Drawer";
import "../../index.css";
import { Link, useNavigate } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { useEffect, useState } from "react";
import { getNextChallenge } from "../../utils/challenge-helper";

function NavBar() {
  const [notificationCount, setNotificationCount] = useState(0);
  const check = async () => {
    try {
      const response = await getNextChallenge();
      if (response.success) {
        if (response.data.live) {
          const challengeId = localStorage.getItem("challengeId");
          if (!challengeId) {
            localStorage.setItem("challengeId", response.data._id);
          }
          if (challengeId === response.data._id) {
            const dismissed = localStorage.getItem("challenge");
            if (dismissed === "true") {
              setNotificationCount(0);
            } else {
              setNotificationCount(1);
            }
          } else {
            localStorage.setItem("challengeId", response.data._id);
            localStorage.setItem("challenge", false);
            setNotificationCount(1);
          }
        } else {
          setNotificationCount(0);
          localStorage.setItem("challenge", false);
        }
      } else {
        setNotificationCount(0);
        localStorage.setItem("challenge", false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    check();
  }, []);

  const handleNotificationClick = () => {
    setNotificationCount(0);
    localStorage.setItem("challenge", true);
  };
  const theme = createTheme({
    typography: {
      fontFamily: ["Permanent Marker"],
      color: "#FFCC00",
    },
  });
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  return (
    <AppBar
      sx={{
        "& .MuiToolbar-root": {
          background: "#FDF5E8",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
          py: {
            xs: "10px",
            md: "0px",
          },
        },
        "& .MuiPaper-root": {
          background: "transparent !important",
        },
        background: "#FDF5E8",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
        zIndex: 1000,
      }}
      color="transparent"
      position={"fixed"}
    >
      <Toolbar>
        {isMatch ? (
          <>
            <DrawerComp />
            <ThemeProvider theme={theme}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                }}
              >
                <img
                  src={require(`../../assets/images/red.png`)}
                  alt="logo"
                  style={{
                    height: "30px",
                    width: "auto",
                    cursor: "pointer",
                  }}
                />
              </Link>
            </ThemeProvider>
          </>
        ) : (
          <>
            <Box
              sx={{
                margin: "auto",
                padding: "15px",
                display: "flex",
                width: "100%",
                maxWidth: "1200px",
                justifyContent: "space-between",
                alignItems: "center",
                whiteSpace: "nowrap !important",
              }}
            >
              <Button
                onClick={() => navigate("/Family")}
                sx={{
                  marginRight: { md: "-20px", lg: "40px" },
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  fontFamily: "Rubik, Sans-Serif",
                  fontSize: { xs: "0.9rem", xl: "1.2rem" },
                  color: "#000",
                  fontWeight: "800",
                }}
              >
                The Family
              </Button>
              <Button
                sx={{
                  marginRight: { md: "-20px", lg: "35px" },
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  fontFamily: "Rubik, Sans-Serif",
                  fontSize: { xs: "0.9rem", xl: "1.2rem" },
                  color: "#000",
                  fontWeight: "800",
                }}
                onClick={() => navigate("/order-now")}
              >
                Order Now
              </Button>
              <ThemeProvider theme={theme}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <img
                    src={require(`../../assets/images/red.png`)}
                    alt="logo"
                    style={{
                      height: "30px",
                      width: "auto",
                    }}
                  />
                </Link>
              </ThemeProvider>

              <Button
                onClick={() => {
                  navigate("/TheChallenge");
                  handleNotificationClick();
                }}
                sx={{
                  marginRight: { md: "-20px", lg: "35px" },
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  fontFamily: "Rubik, Sans-Serif",
                  fontSize: { xs: "0.9rem", xl: "1.2rem" },
                  color: "#000",

                  fontWeight: "800",
                }}
              >
                #TheChallenge &nbsp;&nbsp;
                <Badge
                  badgeContent={notificationCount}
                  color="secondary"
                  overlap="rectangular"
                />
              </Button>

              <Button
                onClick={() => navigate("/contact-us")}
                sx={{
                  marginRight: { md: "-20px", lg: "35px" },
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  fontSize: { xs: "0.9rem", xl: "1.2rem" },
                  color: "#000",
                  fontWeight: "800",
                  fontFamily: "Rubik, Sans-Serif",
                }}
              >
                Contact Us
              </Button>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
