import React, { useEffect, useState } from "react";
import { Box, Grid, CardMedia, Typography } from "@mui/material";
import MyImage from "../../assets/images/dobrikswings.png";
import MyImage2 from "../../assets/images/chickenwrap.png";
import MyImage3 from "../../assets/images/MeatKabob.png";
import AOS from "aos";
import "aos/dist/aos.css";

const BoxGroup = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);
  const ColoredLine = ({ color, width }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 2,
        width: width,
        borderColor: color,
      }}
    />
  );
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#fff5e7",
        padding: "80px 0px 80px",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", pl: "22px", pr: "22px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{ display: { xs: "block", sm: "block", md: "block" } }}
        >
          <Box sx={{ alignItems: "center", p: "0px 0px 10px" }}>
            <ColoredLine color={"#febd00"} width={"80%"} />
            <Typography
              variant="h6"
              sx={{
                textTransform: "uppercase",
                alignItems: "center",
                textAlign: "center",
                color: "#febd00",
                fontSize: "30px",
                fontFamily: "Rubik, Sans-serif",
                p: "20px",
              }}
              fontWeight={"800"}
            >
              chicken kabob
            </Typography>
            <ColoredLine color="#febd00" width={"80%"} />
          </Box>

          <CardMedia
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="450"
            component="img"
            image={MyImage2}
            alt="My image"
          />

          <Box sx={{ alignItems: "center", p: "15px 0px" }}>
            <ColoredLine
              color={isMobile ? "#febd00" : "#D91010"}
              width={"80%"}
            />
            <Typography
              variant="h2"
              sx={{
                textTransform: "uppercase",
                alignItems: "center",
                textAlign: "center",
                color: "#1c2123",
                fontSize: "15px",
                fontFamily: "Rubik, Sans-serif",
                lineHeight: "2",
                fontWeight: "800",
                p: { xs: "0px 20px 0 20px", sm: "10px 35px 10px 35px" },
              }}
            >
              {" "}
              Juicy chicken, grilled to perfection with Mediterranean spices
            </Typography>
            <ColoredLine
              color={isMobile ? "#febd00" : "#D91010"}
              width={"80%"}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Box sx={{ alignItems: "center", p: "0px 0px 10px" }}>
            <ColoredLine color="#D91010" width={"80%"} />
            <Typography
              variant="h6"
              sx={{
                textTransform: "uppercase",
                alignItems: "center",
                textAlign: "center",
                fontSize: { xs: "25px", sm: "30px" },
                fontFamily: "Rubik, Sans-serif",
                color: "#D91010",
                p: "20px",
              }}
              fontWeight={"800"}
            >
              dobrik's wings
            </Typography>
            <ColoredLine color="#D91010" width={"80%"} />
          </Box>

          <CardMedia
            data-aos="zoom-in"
            data-aos-delay="400"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="450"
            component="img"
            image={MyImage}
            alt="My image"
          />

          <Box sx={{ alignItems: "center", p: "15px 0px" }}>
            <ColoredLine
              color={isMobile ? "#D91010" : "#febd00"}
              width={"80%"}
            />
            <Typography
              variant="h2"
              sx={{
                textTransform: "uppercase",
                alignItems: "center",
                textAlign: "center",
                color: "#1c2123",
                fontSize: "15px",
                fontFamily: "Rubik, Sans-serif",
                fontWeight: "800",
                lineHeight: "2",
                p: { xs: "0px 20px 0 20px", sm: "10px 35px 10px 35px" },
              }}
            >
              {" "}
              Deliciously Crispy wings, tossed in your sauce of choice.
            </Typography>
            <ColoredLine
              color={isMobile ? "#D91010" : "#febd00"}
              width={"80%"}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Box sx={{ alignItems: "center", p: "0px 0px 10px" }}>
            <ColoredLine color="#febd00" width={"80%"} />
            <Typography
              variant="h6"
              sx={{
                textTransform: "uppercase",
                alignItems: "center",
                textAlign: "center",
                color: "#febd00",
                fontSize: "30px",
                fontFamily: "Rubik, Sans-serif",
                p: "20px",
              }}
              fontWeight={"800"}
            >
              meat kabob
            </Typography>
            <ColoredLine color="#febd00" width={"80%"} />
          </Box>

          <CardMedia
            data-aos="zoom-in"
            data-aos-delay="600"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="450"
            component="img"
            image={MyImage3}
            alt="My image"
          />

          <Box sx={{ alignItems: "center", p: "15px 0px" }}>
            <ColoredLine
              color={isMobile ? "#febd00" : "#D91010"}
              width={"80%"}
            />
            <Typography
              variant="h2"
              sx={{
                textTransform: "uppercase",
                alignItems: "center",
                textAlign: "center",
                color: "#1c2123",
                fontSize: "15px",
                fontFamily: "Rubik, Sans-serif",
                fontWeight: "800",
                lineHeight: "2",
                p: { xs: "0px 10px 0 10px", sm: "10px 35px 10px 35px" },
              }}
            >
              {" "}
              Enjoy the juicy tenderness of our Mediterranean-inspired meat
              kabob!
            </Typography>
            <ColoredLine
              color={isMobile ? "#febd00" : "#D91010"}
              width={"80%"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BoxGroup;
