import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

const CustomDialog = ({ open, handleClose, title, message, buttonText }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ backdropFilter: "blur(2px)" }}
      PaperProps={{
        style: {
          backgroundColor: "#fff5e7",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center", 
          padding: "0 20px 0 20px"
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: "800", color: "#1c2123", fontSize: "25px" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "#1c2123", fontSize: {xs:"18px", sm: "22px"} }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#febd00",
            fontSize: "15px",
            color: "#1c2123",
            "&:hover": {
              backgroundColor: "#febd00",
            },
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
