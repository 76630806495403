import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { CreateEmail } from "../../utils/email-helper";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Typography, Box, Grid } from "@mui/material";
import design8 from "../../assets/images/design8.png";
import design5 from "../../assets/images/design5.png";
import CustomDialog from "../Dialog/index.js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#febd00",
    },
    secondary: {
      main: "#febd00",
    },
  },
});

export default function Nochallenge() {
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required")
        .test(
          "valid-tld",
          "Email must have a valid top-level domain",
          (value) => {
            const tld = !!value && value.split(".").pop();
            return tld.length >= 2;
          }
        ),
    }),
    onSubmit: async () => {
      try {
        const response = await CreateEmail({ email: formik.values.email });
        if (response.success) {
          setOpenDialog(true);
          formik.resetForm();
        } else {
          setErrorMsg(response.message);
        }
      } catch (error) {
        console.log(error);
        setErrorMsg(true);
      }
    },
  });

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleErrorClose = () => {
    setErrorMsg(false);
  };
  return (
    <>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          position: "relative",
          zIndex: "1",
          display: { md: "block", sm: "none", xs: "none" },
        }}
      >
        <img
          src={design8}
          alt="design"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "45vh",
            width: "23vh",
          }}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(85vh - 60px)",
          height: "100%",
          backgroundColor: "#fff5e7",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: { xs: "0", sm: "0 0 13%" },
          position: "relative",
          width: "100%",
        }}
        align="center"
      >
        <Box
          sx={{
            backgroundColor: "white",
            pr: { xl: "100px", lg: "80px", md: "60px", sm: "30px", xs: "20px" },
            pl: { xl: "100px", lg: "80px", md: "60px", sm: "30px", xs: "20px" },
            pb: { xl: "80px", lg: "60px", md: "50px", sm: "40px", xs: "30px" },
            pt: { xl: "50px", lg: "40px", md: "30px", sm: "20px", xs: "10px" },
            mt: { xl: "80px", lg: "60px", md: "40px", sm: "20px", xs: "10px" },
          }}
        >
          <Typography
            fontFamily={"Permanent Marker"}
            sx={{ color: "#D91010", fontSize: "55px", mb: "20px" }}
            fontWeight="bold"
          >
            <PriorityHighIcon sx={{ mr: "2px" }} />
            Bad news
            <PriorityHighIcon sx={{ ml: "2px" }} />
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "bold", color: "#1c2123" }}
          >
            No Active challenge for the upcoming days.{" "}
            <SentimentVeryDissatisfiedIcon sx={{ fontSize: "25px" }} />
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#1c2123",
              mt: "10px",
              mb: "10px",
              fontSize: "15px",
            }}
          >
            <i>Drop your email to recieve our latest events.</i>
          </Typography>
          {successMsg ? (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#355E3B",
                mb: "10px",
              }}
            >
              {successMsg}
            </Typography>
          ) : null}
          {errorMsg ? (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "red",
                mb: "10px",
              }}
            >
              {errorMsg}
            </Typography>
          ) : null}

          <form onSubmit={formik.handleSubmit}>
            <ThemeProvider theme={theme}>
              <TextField
                variant="outlined"
                sx={{
                  display: "flex",
                  textAlign: "center",
                }}
                InputProps={{
                  style: {
                    backgroundColor: "white",
                    alignContent: "center",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "#1c2123",
                    fontSize: "15px",
                    fontWeight: "600",
                  },
                }}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange(event);
                  setErrorMsg("");
                  setSuccessMsg("");
                }}
                type="email"
                value={formik.values.email}
                disabled={formik.isSubmitting}
                name="email"
                label="Email Address"
                margin="normal"
                fullWidth
              />
            </ThemeProvider>
            <Button
              type="submit"
              sx={{
                mt: { xl: "40px", md: "30px", sm: "6vw", xs: "8vw" },
                color: "white",
                fontWeight: "800",
                fontFamily: "Rubik,sans-serif",
                padding: {
                  xs: "20px 30px",
                  sm: "15px 30px",
                },
                backgroundColor: "#D91010",
                "&:hover": { backgroundColor: "#D91010" },
              }}
            >
              Subscribe
            </Button>
          </form>
        </Box>
      </Box>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          position: "relative",
          display: { md: "block", sm: "none", xs: "none" },
        }}
      >
        <img
          src={design5}
          alt="design"
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            height: "50vh",
            width: "25vh",
          }}
        />
      </Grid>
      <CustomDialog
        open={openDialog}
        handleClose={handleClose}
        title="Success"
        message="You Successfully Subscribed!"
        buttonText="Ok"
      />
      {errorMsg && (
        <CustomDialog
          open={!!errorMsg}
          handleClose={handleErrorClose}
          title="Failed"
          message={errorMsg}
          buttonText="Ok"
        />
      )}
    </>
  );
}
