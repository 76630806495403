import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import { CreateEmail } from "../../utils/all-helper";
import { CreateToast } from "../../utils/global_functions";
import { Close } from "@mui/icons-material";

function PopUp({ open, handleClose }) {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    }),
    onSubmit: async () => {
      try {
        const response = await CreateEmail({ email: formik.values.email });
        console.log(response, "response");
        if (response.success) {
          handleClose();
          CreateToast({
            message: "Email added successfully",
          });
          setTimeout(() => {
            formik.resetForm();
          }, 200);
        } else {
          CreateToast({
            message: response.message,
            error: true,
          });
        }
      } catch (error) {
        CreateToast({
          message: "Something went wrong",
          error: true,
        });
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: "#FFF8EE",
          height: "350px",
          borderRadius: "15px",
          overflow: "hidden",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Close
          onClick={() => {
            handleClose();
            formik.resetForm();
          }}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            color: "#1C2123",
          }}
        />
        <Box
          sx={{
            width: "45%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FEBD00",
          }}
        >
          <img
            draggable="false"
            src={require("../../assets/images/jon-kab.png")}
            alt="jon-kab"
            style={{
              width: "60%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
        <Box
          sx={{
            width: "65%",
            height: "100%",
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "medium",
              fontSize: {
                xs: "18px",
                sm: "20px",
                md: "22px",
                lg: "24px",
              },
              color: "#D91010",
              maxWidth: "80%",
              marginBottom: "20px",
              textAlign: "center",
              lineHeight: "1.5",
            }}
          >
            BUCKLE UP PEOPLE, YOU'VE JUST REACHED JONAH'S KABOB!
          </Typography>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              width: "80%",
              margin: "0 auto",
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="email"
              name="email"
              placeholder="Email"
              variant="outlined"
              disabled={formik.isSubmitting}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#c4c4c4",
                  borderRadius: "10px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#c4c4c4",
                    borderRadius: "10px",
                  },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#c4c4c4",
                    borderRadius: "10px",
                  },

                "& .MuiInputLabel-outlined": {
                  color: "#000000",
                },
                "&:hover .MuiInputLabel-outlined": {
                  color: "#000000",
                },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "#000000",
                },
              }}
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#D91010 !important",
                color: "#fff !important",
                fontWeight: "bold",
                fontSize: "16px",
                borderRadius: "10px",
                padding: "10px 30px",
              }}
              type="submit"
            >
              {formik.isSubmitting ? "Subscribing..." : "Subscribe"}
            </Button>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
}

export default PopUp;
