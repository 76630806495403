import { Typography, Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";

import image1 from "../../assets/images/Grubhub1.png";
import image2 from "../../assets/images/Ubereats1.png";
import image3 from "../../assets/images/Postmates1.png";
import image4 from "../../assets/images/Doordash1.png";
import image5 from "../../assets/images/foodcolors.png";
import { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import KebabDiningRoundedIcon from "@mui/icons-material/KebabDiningRounded";
import CleanHandsRoundedIcon from "@mui/icons-material/CleanHandsRounded";
import design5 from "../../assets/images/design5.png";
import design6 from "../../assets/images/design6.png";

function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 1000,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

function Text({ t }) {
  const { text } = useSpring({
    from: { text: "" },
    text: t,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{text}</animated.div>;
}

function Links() {
  useEffect(() => {}, []);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 850);
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {!isMobile && (
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <img
            src={design6}
            alt="food 2"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "50vh",
              width: "25vh",
              zIndex: "1",
            }}
          />
        </Grid>
      )}
      <Box
        sx={{
          backgroundColor: "#fff5e7",
          pt: {
            xs: "90px",
            md: "100px",
            lg: "110px",
          },
        }}
      >
        <Grid container spacing={7} justifyContent="center" sx={{ mt: "0px" }}>
          <Grid
            item
            sx={{
              zIndex: "3",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "90%",
                },
                mx: "auto",
              }}
            >
              <img
                src={require("../../assets/images/does.png")}
                alt="does"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                  marginBottom: "20px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ backgroundColor: "#fff5e7", mt: "100px" }}>
          <Grid
            container
            spacing={{
              xs: 1,
              sm: 2,
            }}
            justifyContent="center"
          >
            <Grid item xs={6} sm={4} md={2} lg={2} sx={{ mb: "15px" }}>
              <Link to="https://www.grubhub.com/restaurant/jonahs-kabob-12461-oxnard-st-los-angeles/2964341">
                <img
                  src={image1}
                  alt="grubhub"
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "contain",
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={2} sx={{ mb: "15px" }}>
              <Link to="https://www.ubereats.com/store/jonahs-kabob/Q44mwIjEU4mgUZN37ugAXw">
                <img
                  src={image2}
                  alt="ubereats"
                  style={{
                    width: "100%",
                    height: "150px",

                    objectFit: "contain",
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={2} sx={{ mb: "15px" }}>
              <Link to="https://postmates.com/store/jonahs-kabob/Q44mwIjEU4mgUZN37ugAXw">
                <img
                  src={image3}
                  alt="postmates"
                  style={{
                    width: "100%",
                    height: "150px",

                    objectFit: "contain",
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={2} sx={{ mb: "15px" }}>
              <Link to="https://www.doordash.com/store/jonah%27s-kabob-los-angeles-2431279/">
                <img
                  src={image4}
                  alt="doordash"
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "contain",
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={2} sx={{ mb: "15px" }}>
              <Link to="https://food.orders.co/jonahskabob/?utm_source=hoobe&utm_medium=social">
                <img
                  src={image5}
                  alt="food orders"
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "contain",
                  }}
                />
              </Link>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ backgroundColor: "#fff5e7", mt: "120px", pb: "100px" }}>
          <Grid
            container
            spacing={{
              xs: 10,
              sm: 2,
            }}
            justifyContent="center"
          >
            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography align="center">
                <AcUnitRoundedIcon
                  sx={{ fontSize: "70px", color: "#febd00" }}
                />
              </Typography>
              <Typography
                variant="h5"
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "#1c2123",
                  fontFamily: "Rubik,Sans-serif",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Number n={100} />
                  <Text t={"%"} />
                </span>{" "}
                Refreshing
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography align="center">
                <KebabDiningRoundedIcon
                  sx={{ fontSize: "70px", color: "#febd00" }}
                />
              </Typography>
              <Typography
                variant="h5"
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "#1c2123",
                  fontFamily: "Rubik,Sans-serif",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Number n={100} />
                  <Text t={"%"} />
                </span>{" "}
                Delicious
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              lg={2}
              sx={{
                zIndex: "3",
              }}
            >
              <Typography align="center">
                <CleanHandsRoundedIcon
                  sx={{ fontSize: "70px", color: "#febd00" }}
                />
              </Typography>
              <Typography
                variant="h5"
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "#1c2123",
                  fontFamily: "Rubik,Sans-serif",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Number n={100} />
                  <Text t={"%"} />
                </span>{" "}
                Clean
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!isMobile && (
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <img
            src={design5}
            alt="food 2"
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: "50vh",
              width: "25vh",
              zIndex: "2",
            }}
          />
        </Grid>
      )}
    </>
  );
}
export default Links;
