import { ApiCall } from "./global_functions";

export const CreatePhoneNumber = async (phone) => {
  const res = await ApiCall({
    api: "phonenumber/add",
    post: true,
    map: phone,
  });

  return res;
};

export const CreateEmail = async (email) => {
  const res = await ApiCall({
    api: "addEmail",
    post: true,
    map: email,
  });

  return res;
}; 

export const createContact = async (contact) => {
  const res = await ApiCall({
    api: "contacts",
    post: true,
    map: contact,
  });

  return res;
};