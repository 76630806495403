import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function Catering() {
  return (
    <Box
      sx={{
        marginTop: "60px",
        display: "grid",
        placeItems: {
          xs: "center",
          md: "end",
        },
        gridTemplateColumns: {
          xs: "1fr",
          lg: "1fr 1fr",
        },
      }}
    >
      <Box
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
          width: "100%",
          height: "100%",
        }}
      >
        <img
          src={require("../../assets/images/catering.png")}
          alt="catering"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            objectPosition: "left bottom",
            marginBottom: "-10px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            lg: "none",
          },
          width: "100%",
          height: "100%",
        }}
      >
        <img
          src={require("../../assets/images/catering-s.png")}
          alt="catering"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            objectPosition: "left bottom",
            marginBottom: "-10px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: {
            xs: "20px",
            sm: "0px",
          },
        }}
      >
        <img
          src={require("../../assets/images/catering-txt.png")}
          alt="catering-txt"
          style={{
            width: {
              xs: "200px",
              sm: "250px",
              md: "300px",
              lg: "350px",
            },
            height: "auto",
            objectFit: "contain",
            marginBottom: "20px",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            fontWeight: "700",
            fontSize: {
              xs: "2rem",
              sm: "2.5rem",
            },
            maxWidth: "80%",
            marginBottom: "20px",
            color: "#FEBD01",
          }}
        >
          DELECIOUS CUISINE
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "700",
            fontSize: {
              xs: "1.3rem",
              sm: "1.6rem",
            },
            maxWidth: "80%",
            marginBottom: "10px",
            color: "black",
          }}
        >
          We bring the feast to you!
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600",
            fontSize: {
              xs: "1.4rem",
              sm: "1.2rem",
            },
            maxWidth: {
              xs: "90%",
              sm: "70%",
            },
            marginTop: {
              xs: "10px",
              sm: "15px",
            },
            marginBottom: "20px",
            color: "black",
            letterSpacing: "1px",
            lineHeight: "1.3",
          }}
        >
          Elevate events with Jonah's Kabob! Savor juicy chicken, beef, and
          vegetarian kabobs. Pair with tempting sides. Culinary excellence,
          finest ingredients. Customize, embrace memories, make gatherings
          unforgettable. Elevate flavors with Jonah's Kabob!
        </Typography>
        <Link
          to="/contact-us"
          style={{
            textDecoration: "none",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#D91010 !important",
              color: "white !important",
              marginTop: "20px",
              fontSize: {
                xs: "26px",
                md: "28px",
              },
              borderRadius: "10px",
              minWidth: "180px",
              padding: "4px 20px",
              marginBottom: "50px",
            }}
          >
            Contact Us
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default Catering;
