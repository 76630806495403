import { Box } from "@mui/material";
import React from "react";
import HomePageHero from "../../components/Home-Page/hero";
import MeetUs from "../../components/Home-Page/meet-us";
import HomePageOptions from "../../components/Home-Page/options";
import Catering from "../../components/Home-Page/catering";
import Jonah from "../../components/Home-Page/jonah";

function HomePage() {
  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "hidden",
      }}
    >
      <HomePageHero />
      <Box
        sx={{
          backgroundColor: "#FFF8EE",
        }}
      >
        <Jonah />
      </Box>
      <Box
        sx={{
          px: "20px",
          backgroundColor: "#febd00",
        }}
      >
        <MeetUs />
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFF8EE",
        }}
      >
        <HomePageOptions />
        <Catering />
      </Box>
    </Box>
  );
}

export default HomePage;
