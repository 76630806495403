import {
  List,
  ListItemButton,
  ListItemIcon,
  Box,
  IconButton,
  Typography,
  createTheme,
  ThemeProvider,
  Badge,
  SwipeableDrawer,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { getNextChallenge } from "../../utils/challenge-helper";
import {
  EmojiEvents,
  FamilyRestroom,
  Home,
  LocalDining,
  LocalPostOffice,
  Tag,
} from "@mui/icons-material";

function DrawerComp() {
  const currentPath = window.location.pathname;
  const notHome = currentPath !== "/";
  const router = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const [past, setPast] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > window.innerHeight * 0.2) {
        setPast(true);
      } else {
        setPast(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const check = async () => {
    try {
      const response = await getNextChallenge();
      if (response.success) {
        if (response.data.live) {
          const challengeId = localStorage.getItem("challengeId");
          if (!challengeId) {
            localStorage.setItem("challengeId", response.data._id);
          }
          if (challengeId === response.data._id) {
            const dismissed = localStorage.getItem("challenge");
            if (dismissed === "true") {
              setNotificationCount(0);
            } else {
              setNotificationCount(1);
            }
          } else {
            localStorage.setItem("challengeId", response.data._id);
            localStorage.setItem("challenge", false);
            setNotificationCount(1);
          }
        } else {
          setNotificationCount(0);
          localStorage.setItem("challenge", false);
        }
      } else {
        setNotificationCount(0);
        localStorage.setItem("challenge", false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    check();
  }, []);

  const handleNotificationClick = () => {
    setNotificationCount(0);
    localStorage.setItem("challenge", true);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const Pages = [
    { label: "Home", path: "/", icon: (props) => <Home {...props} /> },
    {
      label: "The Family",
      path: "/Family",
      icon: (props) => <FamilyRestroom {...props} />,
    },
    {
      label: "TheChallenge",
      path: "/TheChallenge",
      icon: (props) => <Tag {...props} />,
    },
    {
      label: "Order Now",
      path: "/order-now",
      icon: (props) => <LocalDining {...props} />,
    },
    {
      label: "Contact Us",
      path: "/contact-us",
      icon: (props) => <LocalPostOffice {...props} />,
    },
  ];
  const theme = createTheme({
    typography: {
      fontFamily: ["Permanent Marker"],
      color: "#FFCC00",
    },
  });

  return (
    <>
      <SwipeableDrawer
        onOpen={() => {
          setOpenDrawer(true);
        }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            width: 340,
          },
        }}
      >
        <Box sx={{ height: "100%", backgroundColor: "#fff5e7", px: "30px" }}>
          <ThemeProvider theme={theme}>
            <div
              style={{
                width: "100%",
                margin: "0 auto",
                marginTop: "30px",
              }}
            >
              <img
                src={require(`../../assets/images/red.png`)}
                alt="logo"
                style={{
                  width: "100%",
                  height: "auto",
                }}
                onClick={() => {
                  setOpenDrawer(false);
                  router("/");
                }}
              />
            </div>
          </ThemeProvider>
          <List sx={{ textTransform: "uppercase", mt: "20px" }}>
            {Pages.map((page, index) => (
              <ListItemButton
                key={index}
                sx={{
                  backgroundColor: currentPath === page.path && "#d91010",
                  marginBottom: "10px",
                  borderRadius: "15px",
                  my: "15px",
                }}
                onClick={() => {
                  setOpenDrawer(false);
                  router(page.path);
                }}
              >
                {page.icon({
                  sx: {
                    color: currentPath === page.path ? "#fff" : "#000",
                    mr: "10px",
                  },
                })}
                <ListItemIcon>
                  <Typography
                    sx={{
                      color: currentPath === page.path ? "#fff" : "#000",
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.5rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    {page.label}
                  </Typography>
                </ListItemIcon>
              </ListItemButton>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>

      <IconButton
        sx={{
          color: notHome || past ? "#d91010" : "#FEBD01",
          marginRight: "auto",
        }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon sx={{ fontSize: 35 }} />
      </IconButton>
    </>
  );
}

export default DrawerComp;
