import { Box, Button, Typography } from "@mui/material";
import React from "react";
import HeroBackground from "./hero-bg";
import { Link } from "react-router-dom";
import { LocalDining, ShoppingBag } from "@mui/icons-material";

function HomePageHero() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        position: "relative",
        display: "flex",
        flexFlow: {
          xs: "column",
          md: "row",
        },
        justifyContent: "center",
        alignItems: "center",
        pb: "20px",
        pt: {
          xs: "60px",
          md: "20px",
        },
      }}
    >
      <HeroBackground />
      <Box
        sx={{
          width: {
            xs: "100%",
            md: "40%",
          },
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "white",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            width: { lg: "90%", md: "80%", sm: "70%", xs: "70%" },
            mx: "auto",
            marginBottom: "40px",
          }}
        >
          <img
            draggable="false"
            src={require("../../assets/images/hero-text.png")}
            alt="hero-text"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
        <Link
          to="/order-now"
          style={{
            textDecoration: "none",
          }}
        >
          <Box
            sx={{
              fontSize: {
                xs: "32px",
                md: "36px",
              },
              padding: "12px 17px",
              borderRadius: "10px",
              backgroundColor: "#febd00",
              color: "white",
              fontWeight: "bold",
              cursor: "pointer",
              whiteSpace: "nowrap",
              mb: 2,
            }}
          >
            Order Now
            <LocalDining
              sx={{
                ml: "10px",
                fontSize: {
                  xs: "32px",
                  md: "36px",
                },
                verticalAlign: "middle",
              }}
            />
          </Box>
        </Link>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "25px",
              md: "30px",
            },
            maxWidth: {
              lg: "80%",
              md: "70%",
              sm: "60%",
              xs: "55%",
            },
            lineHeight: "1.8",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          MAKE YOUR TASTE BUDS DO THE NICK-N-NAE-NAE!
        </Typography>
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            md: "60%",
          },
          height: {
            xs: "auto",
            md: "60%",
          },
          mt: {
            xs: "40px",
            md: "0px",
          },
        }}
      >
        <Box
          sx={{
            mx: {
              xs: "auto",
              md: 0,
            },
            width: "90%",
          }}
        >
          <img
            draggable="false"
            src={require("../../assets/images/hero-food.png")}
            alt="hero-food"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default HomePageHero;
