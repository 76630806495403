import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function MeetUs() {
  return (
    <Grid container justifyContent="center" alignItems="center" flexGrow={1}>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          py: "20px",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "80%",
              md: "70%",
            },
            mx: "auto",
            mb: "20px",
          }}
        >
          <img
            draggable="false"
            src={require("../../assets/images/order-now.png")}
            alt="order-now"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
        <Typography
          variant="h5"
          sx={{
            fontSize: {
              xs: "1rem",
              sm: "1.2rem",
            },
            fontWeight: "bold",
            maxWidth: "80%",
            color: "black",
            textAlign: "center",
          }}
        >
          Easy ordering, mouthwatering dishes. Fresh, quality ingredients.
          Unforgettable flavors that'll leave you craving more. Taste the
          difference and get hooked on our unique creations!
        </Typography>
        <Link
          to="/order-now"
          style={{
            textDecoration: "none",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#D91010 !important",
              color: "white !important",
              marginTop: "20px",
              fontSize: {
                xs: "26px",
                md: "28px",
              },
              borderRadius: "10px",
              minWidth: "250px",
              padding: "4px 20px",
            }}
          >
            Order Now
          </Button>
        </Link>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          maxHeight: "80vh",
        }}
      >
        <img
          draggable="false"
          src={require("../../assets/images/meet-us-bg.png")}
          alt="order-now"
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "80vh",
            objectFit: "contain",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          py: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "60%",
              md: "50%",
            },
            mx: "auto",
            mb: "20px",
          }}
        >
          <img
            draggable="false"
            src={require("../../assets/images/meet-us.png")}
            alt="order-now"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
        <Typography
          variant="h5"
          sx={{
            fontSize: {
              xs: "1rem",
              sm: "1.2rem",
            },
            fontWeight: "bold",
            maxWidth: "80%",
            color: "black",
            textAlign: "center",
          }}
        >
          This is Where Laughter Meets Flavor! Join comedian YouTuber Nick at
          our vibrant store. Indulge in mouthwatering creations, make us your
          go-to destination for food and fun!
        </Typography>
        <Link
          to="/family"
          style={{
            textDecoration: "none",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#D91010 !important",
              color: "white !important",
              marginTop: "20px",
              fontSize: {
                xs: "26px",
                md: "28px",
              },
              borderRadius: "10px",
              minWidth: "250px",
              padding: "4px 20px",
            }}
          >
            Meet Us
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}

export default MeetUs;
