import { Box, Container, Typography } from "@mui/material";
import { Link } from 'react-router-dom';


export default function Four() {
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              padding: "20px"
            }}
          >
            <Typography sx={{fontSize: "150px", fontWeight: "800", letterSpacing: "10px"}}>404</Typography>
            <Typography align="center" color="textPrimary" variant="h1">
              Page Not Found
            </Typography>
            <Typography align="center" color="textPrimary" variant="subtitle1" sx={{mt: "30px", lineHeight: "2"}}>
              The page you are looking for isn’t here.
              You either tried some shady route or you came here by mistake.
              Whichever it is, try using the navigation. 
            </Typography>
            
            <Typography sx={{color: "#d91010", fontWeight: "bold", mt: "20px"}}>
            <Link to="/">Enter here to redirect you to the home page</Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
