import { ApiCall } from "./global_functions";

export const getNextChallenge = async () => {
  const res = await ApiCall({
    api: "challenge",
    get: true,
    map: {},
  });

  return res;
};
