import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import TimerPage from "../../components/TimerPage";
import { getNextChallenge } from "../../utils/challenge-helper";
import TheChallengeAnnouncement from "../../components/Announcement";
import FadeLoader from "react-spinners/FadeLoader";
import Nochallenge from "../../components/NoChallengePopup";
import design2 from "../../assets/images/design2.png";
import design13 from "../../assets/images/design13.png";

export default function TheChallenge() {
  const [loading, setLoading] = useState(true);
  const [challengeTimer, setChallengeTimer] = useState(false);
  const [challengeAnnounce, setChallengeAnnounce] = useState(false);
  const [noChallenge, setnoChallenge] = useState(false);

  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [hashtag, setHashtag] = useState("");
  const [description, setDescription] = useState("");

  const check = async () => {
    setLoading(true);
    try {
      const response = await getNextChallenge();
      if (response.success) {
        if (response.data.live) {
          setChallengeAnnounce(true);
          setTitle(response.data.title);
          setHashtag(response.data.hashtag);
          setDescription(response.data.description);
        } else {
          setDate(response.data.startDate);
          setChallengeTimer(true);
        }
      } else {
        setnoChallenge(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    check();
  }, []);
  return (
    <Box
      sx={{
        pt: {
          xs: "110px",
        },
        position: "relative",
        backgroundColor: "#fff5e7",
      }}
    >
      {!loading ? (
        <>
          {challengeTimer && (
            <>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: { md: "block", sm: "block", xs: "none" },
                }}
              >
                <img
                  src={design2}
                  alt="design"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: "20vh",
                    width: "25vh",
                  }}
                />
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "100vh",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <TimerPage targetDate={date} />
                </Box>
              </Box>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  position: "relative",
                  display: { lg: "block", md: "block", sm: "none", xs: "none" },
                }}
              >
                <img
                  src={design13}
                  alt="design"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    height: "20vh",
                    width: "35vh",
                  }}
                />
              </Grid>
            </>
          )}

          {challengeAnnounce && (
            <Box>
              <TheChallengeAnnouncement
                title={title}
                hashtag={hashtag}
                description={description}
              />
            </Box>
          )}

          {noChallenge && <Nochallenge />}
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            backgroundColor: "#fff5e7",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FadeLoader color="#Febd00" />
          <p color="#1c2123">Page is loading...</p>
        </Box>
      )}
    </Box>
  );
}
