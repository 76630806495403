import { Box, CardActionArea, CardMedia, Grid } from "@mui/material";
import Ours from "../../components/Findus";
import image from "../../assets/images/image.jpg";
import CardPic from "../../components/CardPic";
import React from "react";
import imageNick from "../../assets/images/nickHome.png";
import homeCatering from "../../assets/images/cateringHome.png";

import BoxGroup from "../../components/BoxGroup";
import Banner from "../../components/HomeBanner";
import AOS from "aos";
import "aos/dist/aos.css";

export default function HomePage() {
  React.useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Box sx={{ backgroundColor: "#fff5e7" }}>
        <Banner />
        <CardPic
          heading="Jonah's"
          subheading="Kabob"
          slogan="Making your taste buds do the Nick-n-Nae-Nae!"
          description="Welcome to our Mediterranean restaurant, where the food is infused with a taste of laughter. Our restaurant isn't just about Nick's comedic talent and culinary skills - it's a family business, with each member bringing their own unique touch to the table. From the secret family recipes passed down from Nick's grandmother to the warm and welcoming atmosphere created by our friendly staff, every aspect of our restaurant is infused with a sense of family and tradition. So come on down and experience the delicious flavors and fun-loving vibe that have made our restaurant a beloved fixture in the community for generations.
        "
          buttonText="Order now"
          imageSrc={imageNick}
          bgColor="#fff5e7"
          headingColor="#d91010"
          subheadingColor="#d91010"
          descriptionColor="#1c2123"
          showButton={false}
        />
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#Febd00",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={0}
            justifyContent="center"
            sx={{ px: "30px", overflow: "hidden", pt: "20px", pb: "20px" }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Ours
                  first_title="Order"
                  second_title="now"
                  par="Ordering delicious dishes from our menu has never been easier! Choose 
                  your favorite items and pay securely either in person or online with our 
                  hassle-free system.Indulge in a burst of flavor with every bite of our expertly crafted dishes,
                  made with only the freshest and highest quality ingredients. Once you try our unique
                   flavor combinations, you'll be hooked!"
                  btn="order now"
                  backColor="white"
                  btn_color="#D91010"
                  btn_color_hover="#D91010"
                  anim_name="flip-up"
                  anim_easing="ease-out-cubic"
                  anim_duration="1500"
                  routing="/order-now"
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box
                data-aos="flip-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                sx={{
                  overflow: "hidden",
                  mt: { md: "60px" },
                  width: "100%",
                  height: "600px",
                }}
              >
                <CardActionArea
                  sx={{
                    height: "100%",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={image}
                    alt="food"
                    width={"100%"}
                    height={"100%"}
                    sx={{ objectFit: "cover" }}
                  ></CardMedia>
                </CardActionArea>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Ours
                  first_title="Meet"
                  second_title="Us"
                  par="Meet the family behind our beloved Mediterranean restaurant! Our
                   family business contributes their own unique touch to create a warm
                  and welcoming atmosphere. From secret family recipes to a friendly staff,
                  our restaurant is infused with a sense of tradition that has made us a
                  beloved fixture in the community for generations."
                  anim_name="flip-up"
                  anim_easing="ease-out-cubic"
                  anim_duration="2500"
                  btn="meet us"
                  backColor="white"
                  btn_color="#D91010"
                  btn_color_hover="#D91010"
                  routing="/Family"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <BoxGroup />
        <CardPic
          heading="Catering"
          subheading="Delicious Mediterranean Cuisine"
          slogan="We bring the feast to you!"
          description="Welcome to Jonah's Kabob Catering! We specialize in providing exceptional
           Mediterranean cuisine for all occasions. Our menu offers a variety of mouth-watering kabobs,
            including chicken, beef, and vegetarian options, along with sides like homemade hummus and 
            crispy falafel. Our skilled chefs use only the finest ingredients to ensure that every meal 
            is made to perfection. Contact us today to learn more about our customizable catering options
             and let us take your event to the next level with our delectable Mediterranean cuisine!
        "
          buttonText="Contact Us"
          imageSrc={homeCatering}
          bgColor="#fff5e7"
          headingColor="#d91010"
          subheadingColor="#d91010"
          descriptionColor="#1c2123"
          buttonColor="white"
          buttonBgColor="#d91010"
          showButton={true}
        />
      </Box>
    </>
  );
}
