import {
  Box,
  Button,
  Link,
  ThemeProvider,
  createTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import avatarImage from "../../assets/images/yelp.png";
import avatarImage2 from "../../assets/images/insta1.png";
import { useNavigate, useLocation } from "react-router-dom";
import PrivacyPage from "../../views/PrivacyPage";

import { useState } from "react";

function Footer() {
  const [openTerms, setOpenTerms] = useState(false);

  const theme = createTheme({
    typography: {
      fontFamily: ["Permanent Marker"],
      color: "#FFCC00",
    },
  });
  const location = useLocation();
  const currentPage = location.pathname;
  const scrollToTop = (name) => {
    if (name === currentPage) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  };

  const handleClickOpen = () => {
    setOpenTerms(true);
  };

  const handleClose = () => {
    setOpenTerms(false);
  };

  const navigate = useNavigate();

  return (
    <>
      <Box
        component="footer"
        sx={{
          position: "static",
          bottom: 0,
          width: "100%",
          height: "160px",
          backgroundColor: "#febd00",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Box sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "20px",
              my: "10px",
            }}
          >
            <Button
              onClick={() => {
                navigate("/Family");
                scrollToTop("/Family");
              }}
              sx={{
                fontSize: { sm: "0.75em", md: "0.9em", lg: "1em", xl: "1.5em" },
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
                color: "#1c2123",
                minWidth: "150px",
              }}
            >
              The Family
            </Button>
            <Button
              onClick={() => {
                navigate("/order-now");
                scrollToTop("/order-now");
              }}
              sx={{
                fontSize: { sm: "0.75em", md: "0.9em", lg: "1em", xl: "1.5em" },
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
                color: "#1c2123",
                minWidth: "150px",
              }}
            >
              Order Now
            </Button>

            <ThemeProvider theme={theme}>
              <img
                src={require("../../assets/images/red.png")}
                alt="red"
                style={{
                  height: "20px",
                  width: "auto",
                  objectFit: "contain",
                  marginRight: "20px",
                }}
              />
            </ThemeProvider>
            <Button
              onClick={() => {
                navigate("/TheChallenge");
                scrollToTop("/TheChallenge");
              }}
              sx={{
                fontSize: { sm: "0.75em", md: "0.9em", lg: "1em", xl: "1.5em" },
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
                color: "#1c2123",
                minWidth: "150px",
              }}
            >
              #TheChallenge
            </Button>
            <Button
              onClick={() => {
                navigate("/contact-us");
                scrollToTop("/contact-us");
              }}
              sx={{
                fontSize: { sm: "0.75em", md: "0.9em", lg: "1em", xl: "1.5em" },
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
                color: "#1c2123",
                minWidth: "150px",
              }}
            >
              Contact Us
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "12px",
            }}
          >
            <Link
              href="https://www.instagram.com/jonahskabob/?hl=en"
              target={"_blank"}
            >
              <Avatar src={avatarImage2} />
            </Link>

            <Link
              href="https://www.yelp.com/biz/jonahs-kabob-valley-glen"
              target={"_blank"}
            >
              <Avatar src={avatarImage} />
            </Link>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                color: "#1c2123",
                fontWeight: "bold",
                fontSize: { xs: "10px", sm: "14px", lg: "16px" },
              }}
              onClick={handleClickOpen}
            >
              Terms of Service | Privacy Policy
            </Button>
            <Dialog
              open={openTerms}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                height: { xs: "100%", sm: "100%" },
                ml: "20px",
                mr: "20px",
              }}
              PaperProps={{
                style: {
                  backgroundColor: "#fff5e7",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "70%",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
                {"Terms of Service and Privacy Policy"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{ pr: "10px", pl: "10px" }}
                >
                  <PrivacyPage />
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
