import { ApiCall } from "./global_functions";

export const CreateEmail = async (email) => {
    const res = await ApiCall({
      api: "addEmail",
      post: true,
      map: email,
    });
  
    return res;
  }; 