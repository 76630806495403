import { ApiCall } from "./global_functions";

export async function getFamilyMembers() {
  const response = await ApiCall({
    api: "family/members",
    map: {},
  });
  if (response.success) {
    return {
      state: true,
      familyMembers: response.data,
    };
  }
  return {
    state: false,
    story: {},
  };
}
