import { CardContent, Typography, Card, Box, Grid } from "@mui/material";
import ReactConfetti from "react-confetti";
import { useMediaQuery } from "@material-ui/core";

import React from "react";
const ColoredLine = ({ color, p }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
      flex: 1,
      borderColor: "#febd00",
      padding: p,
    }}
  />
);

function TheChallengeAnnouncement(props) {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 868);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const matches1 = useMediaQuery("(max-height: 350px)");
  const matches2 = useMediaQuery("(max-height: 450px)");
  const matches3 = useMediaQuery("(max-height: 540px)");

  return (
    <>
      <Box
        className="box"
        sx={{
          minHeight: "calc(87vh - 60px)",
          height: "100%",
          backgroundColor: "#fff5e7",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: { xs: "0", sm: "0 0 13%" },
          position: "relative",
          width: "100%",
        }}
        align="center"
      >
        {!isMobile && (
          <ReactConfetti style={{ width: "100%", height: "100%" }} />
        )}
        <Box
          sx={{
            pt: "100px",
            width: "100%",
            backgroundColor: "#fff5e7",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            gap: {
              xs: "0px",
              sm: "10px",
            },
            mb: "100px",
          }}
        >
          <ColoredLine color="#febd00" />
          <Typography
            sx={{
              fontSize: { md: "24px", xs: "25px", lg: "30px" },
              textAlign: "center",
              color: "#d91010",
              fontWeight: "700",
              maxWidth: {
                xs: "70%",
              },
              mt: matches3 ? { xs: "100px" } : { xs: "0px" },
            }}
          >
            Are you prepared to face a challenge head-on?
          </Typography>
          <ColoredLine color="#febd00" />
        </Box>

        <Grid container justifyContent={"center"}>
          <Box
            height="100%"
            sx={{
              width: { lg: "100%", xs: "60%" },
              mt: matches3 ? { xs: "100px" } : { xs: "0px" },
            }}
          >
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Card
                sx={{
                  width: { md: "70%", xs: "100%", lg: "45%", sm: "90%" },
                  mt: "10px",
                  mb: { xs: "30px" },
                  ml: { xs: "-20px" },
                  padding: "25px",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: {
                        md: "30px",
                        lg: "35px",
                        sm: "32px",
                        xs: "25px",
                      },
                      fontFamily: "Rubik,sans-serif",
                      fontWeight: "Bold",
                      color: "#d91010",
                      mb: "15px",
                      borderRadius: "0px",
                      display: "block",
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    {props.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { md: "20px", lg: "15px", sm: "20px" },
                      fontFamily: "Rubik,sans-serif",
                      fontWeight: "Bold",
                      pt: "30px",
                      textAlign: "center",
                      display: "block",
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    {props.description}
                    <br />
                    <br />
                    <span
                      style={{
                        fontSize: "25px",
                        fontFamily: "Rubik,sans-serif",
                        fontWeight: "900",
                        color: "#d91010",
                        display: "block",
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      #{props.hashtag}
                    </span>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  );
}
export default TheChallengeAnnouncement;
