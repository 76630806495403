import {
  Button,
  Typography,
  Box,
  CardMedia,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import backgroundImage from "../../assets/images/hah.png";
import backgroundMobile from "../../assets/images/backgroundMobile.jpeg";
import main from "../../assets/images/main.png";
import { useMediaQuery } from "@material-ui/core";

function Banner() {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  function ClickButton(event) {
    event.preventDefault();
    navigate("/order-now");
    scrollToTop();
  }
  const matches3 = useMediaQuery("(max-height: 680px)");
  const matches1 = useMediaQuery("(max-width: 400px)");

  return (
    <Box
      sx={{
        backgroundImage: {
          sm: `url(${backgroundImage})`,
          xs: `url(${backgroundMobile})`,
        },
        height: {
          lg: "700px",
          md: "850px",
          sm: "840px",
          xs: "880px",
        },

        display: "flex",
        flexDirection: { xs: "column" },
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        mb: { lg: "160px", sm: "80px", xs: "80px" },
      }}
    >

        <CardMedia
        className="imageContainer"
          component="img"
          image={main}
          alt="design"
          sx={{
            width: {
              xl: "620px",
              lg: "590px",
              md: "520px",
              sm: "388px",
              xs: "352px",
            },
            height: {
              xl: "640px",
              lg: "610px",
              md: "540px",
              sm: "400px",
              xs: "365px",
            },
            position: "absolute",
            right: { lg: "0px", md: "0%", sm: "0%", xs: "0%" },
            top: !matches1
              ? {
                  xl: "-10px",
                  lg: "15px",
                  md: "240px",
                  sm: "419px",
                  xs: "640px",
                }
              : { xs: "677px" },
            mt: { lg:"170px",md: "165px",xs:"-45.5px",sm:"110px" },
            minHeight: "350px",
            minWidth:"340px",
          }} 
          
        ></CardMedia>

      <Box
        sx={{
          position: "relative",
          minHeight: "20vh",
          height: "80vh",
          mb: "180px",
          textAlign: "left",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: { md: "25%", xs: "50%" },
            transform: "translate(-50%, -50%)",
            height: { lg: "100%", md: "100%", sm: "24%" },
          }}
        >
          <Box
            sx={{
              mt: matches3
                ? { lg: "100px", md: "140px", sm: "-90px", xs: "100px" }
                : { lg: "100px", md: "140px", sm: "-145px", xs: "-100px" },
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: "38px", md: "50px", lg: "60px", xs: "40px" },
              }}
              color="#d91010"
              align="center"
              fontFamily={"Permanent Marker"}
              fontWeight="bold"
            >
              LA's Finest Kabob
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "17px", md: "25px", lg: "25px", xs: "19px" },
                mt: { md: "40px",sm:"28px" },
              }}
              color="white"
              align="center"
              fontWeight="700"
            >
              MAKING YOUR TASTE BUDS DO <br />
              THE NICK-N-NAE-NAE!
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "23px", md: "25px", lg: "25px", xs: "23px" },
                mt: {md:"16px",sm:"10px"},
              }}
              color="#d91010"
              align="center"
              fontFamily={"Permanent Marker"}
            >
              Call us now!
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "23px", md: "25px", lg: "25px", xs: "23px" },
              }}
              color="#d91010"
              align="center"
              fontFamily={"Permanent Marker"}
            >
              +1 818-856-8880
            </Typography>
          </Box>
          <Box sx={{ mt: {md:"16px",sm:"5px"}, textAlign: "center", pb: { xs: "20px" } }}>
            <Button
              onClick={ClickButton}
              sx={{
                backgroundColor: "#d91010",
                color: "white",
                fontSize: { xs: "15px", md: "21px", sm: "20px" },
                fontFamily: "Rubik,sans-serif",
                fontWeight: "800",
                padding: { xs: "20px 30px", sm: "11px 26px",md:"15px 30px" },
                borderRadius: "5px",
                "&:hover": { backgroundColor: "#d91010" },
                cursor: "pointer",
              }}
            >
              Order now
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Banner;
