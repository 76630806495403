import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import HomePage from "./views/Home Page";
import HomePage2 from "./views/Home Page/dep";
import Link from "./components/LinkMenu";
import FamilyPage from "./views/Family Page";
import ContactUsPage from "./views/Contact Us";
import ChallengePage from "./views/ChallengePage";
import TheChallengeAnnouncement from "./components/Announcement";
import FourPage from "./views/404 Page/404.js";
import { Outlet } from "react-router-dom";
import PopupListner from "./components/popup/popup-listner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPage from "./views/PrivacyPage";

function App() {
  return (
    <>
      <ToastContainer />
      <PopupListner />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <NavBar />
              <Outlet />
              <Footer />
            </>
          }
        >
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/old" element={<HomePage2 />} />
          <Route exact path="/order-now" element={<Link />} />
          <Route exact path="/Family" element={<FamilyPage />} />
          <Route exact path="contact-us" element={<ContactUsPage />} />
          <Route exact path="/TheChallenge" element={<ChallengePage />} />
          <Route
            exact
            path="/announce"
            element={<TheChallengeAnnouncement />}
          />
        </Route>
        <Route exact path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/*" element={<FourPage />} />
      </Routes>
    </>
  );
}

export default App;
